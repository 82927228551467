<template>
    <button class="flex gap-2 items-center w-full px-2" @click="isDialogOpen = true">
        <div class="w-8 h-8">
            <CoachingModeMarble />
        </div>
        <div class="text-left grow">
            <p class="text-base font-semibold tracking-tight">Coaching Style</p>
            <p class="text-xs font-semibold tracking-tight text-[#8C8C8C]">{{ currentCoachingStyle }}</p>
        </div>
        <i class="bi bi-chevron-expand text-xl text-[#8C8C8C] iconBold"></i>
    </button>
    <CoachingModeDialog v-if="isDialogOpen" @cancel="isDialogOpen = false" />
</template>

<script setup>
import CoachingModeDialog from "~vue/CoachingModeDialog.vue";
import { useCoachingModeSelect } from "~vue/composables/useCoachingModeSelect";
import { COACHING_STYLES, useChatStore } from "~vue/stores/chatStore";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

import CoachingModeMarble from "./CoachingModeMarble.vue";

const { emitter } = inject("globalProperties");

const { handleCoachingModeSelect } = useCoachingModeSelect(emitter);

const isDialogOpen = ref(false);
const currentCoachingStyle = computed(() => COACHING_STYLES[useChatStore.coachingMode]);

function selectCoachingMode(data) {
    if (data?.detail?.data?.coaching_mode) {
        // Triggers when the coaching mode switches automatically
        useChatStore.setCoachingMode(data.detail.data.coaching_mode);
    } else {
        // Triggers when a user manually switches the coaching mode
        handleCoachingModeSelect(data);
    }
}

onMounted(() => {
    document.addEventListener("coaching_mode_updated", selectCoachingMode);
});

onUnmounted(() => {
    document.removeEventListener("coaching_mode_updated", selectCoachingMode);
});
</script>

<style scoped>
.iconBold {
    -webkit-text-stroke: 1px;
}
</style>
