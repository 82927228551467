<template>
    <ol>
        <template v-for="(message, index) in rolePlayMessages" :key="message.chat_message_id">
            <li
                v-if="feedbackForMessage(message.chat_message_id)"
                :ref="(el) => refForLine(el, message.chat_message_id)"
                class="duration-500 transition-colors ease-in font-medium tracking-tight flex gap-6 hover:bg-gray-50/90 hover:duration-150 rounded-xl p-3 mb-3"
                :class="focusedFeedback === message.chat_message_id ? 'bg-valence-purple/10' : ''"
            >
                <span class="py-2">{{ index + 1 }}</span>
                <button type="button" title="View message" class="text-left leading-[normal] text-[#8C8C8C]" @click="focusChatMessage(message.chat_message_id)">
                    {{ feedbackForMessage(message.chat_message_id) }}
                </button>
            </li>
        </template>
    </ol>
</template>

<script setup>
import { getRolePlayMonitorParams } from "~vue/chatActions";
import { CHAT_EVENT } from "~vue/events";
import { computed, inject } from "vue";

const { emitter } = inject("globalProperties");

const { rolePlayMessages, refForLine, focusedFeedback } = defineProps({
    rolePlayMessages: { type: Array, required: true },
    refForLine: { type: Function, required: true },
    focusedFeedback: { type: String, default: null },
});

const rolePlayMessageMonitorProps = computed(() =>
    rolePlayMessages.reduce((aggregator, current) => {
        const params = getRolePlayMonitorParams(current);
        return {
            ...aggregator,
            [current.chat_message_id]: params,
        };
    }, {}),
);

const feedbackForMessage = (messageId) => {
    const rolePlayMonitorProps = rolePlayMessageMonitorProps.value[messageId];
    return rolePlayMonitorProps && rolePlayMonitorProps.feedback ? rolePlayMonitorProps.feedback : null;
};

const focusChatMessage = (chatMessageId) => {
    emitter.emit(CHAT_EVENT.FOCUS_CHAT_MESSAGE, { chatMessageId });
};
</script>

<style scoped></style>
