<template>
    <button type="button" class="flex items-center font-semibold gap-2 w-full" @click="scheduleFollowupDialog.open()">
        <template v-if="!isScheduled">
            <div class="whitespace-nowrap leading-5 flex items-center gap-4 bg-white border-[#E8E8E8] border-2 tracking-tight rounded-xl p-3 grow" title="Edit date">
                <span>{{ formattedDate.month }}, {{ formattedDate.day }}</span>
                <i class="bi bi-pen text-sm text-[#8C8C8C] ml-auto iconBold"></i>
            </div>
            <div class="whitespace-nowrap leading-5 flex items-center gap-4 bg-white border-[#E8E8E8] border-2 tracking-tight rounded-xl p-3 grow" title="Edit time">
                {{ formattedDate.time }}
                <i class="bi bi-pen text-sm text-[#8C8C8C] ml-auto iconBold"></i>
            </div>
            <div class="ml-2 rounded-full flex items-center justify-center p-3 w-4 h-4 bg-slate-300">
                <i class="bi bi-arrow-right text-[#555BA2] iconBold"></i>
            </div>
        </template>
        <template v-else>
            <div class="whitespace-nowrap leading-5 flex items-center justify-center bg-white border-[#E8E8E8] border-2 tracking-tight rounded-xl p-3 grow">
                <span>{{ formattedDate.month }}, {{ formattedDate.day }} @ {{ formattedDate.time }}</span>
            </div>
            <div class="ml-2 bi bi-pen text-sm text-[#8C8C8C] iconBold"></div>
        </template>
    </button>
    <ScheduleFollowupDialog ref="scheduleFollowupDialog" :initial-date="date" :event-description="inviteDescription" :custom-schedule="scheduleFollowup" />
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, onMounted, ref, useTemplateRef } from "vue";

import { dismissAction } from "./chatActions";
import ScheduleFollowupDialog from "./components/ScheduleFollowupDialog.vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const { data, messageId, lineIdx } = defineProps({
    data: { type: Object, required: true },
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
});

const date = ref(new Date(data.action_params?.event_at_confirmed ?? data.action_params?.event_at_guessed));

const isScheduled = computed(() => data.action_state?.submitted);
const inviteDescription = computed(() => data.action_params?.invite_description ?? "");

const formattedDate = computed(() => {
    const luxonDate = DateTime.fromJSDate(date.value);
    return {
        day: luxonDate.day,
        month: luxonDate.monthShort,
        weekday: luxonDate.weekdayLong,
        time: luxonDate.toLocaleString(DateTime.TIME_SIMPLE),
    };
});

onMounted(() => {
    logUserInteraction("followup_presented", { chat_message_id: messageId }, coachingSessionId);
});

const scheduleFollowupDialog = useTemplateRef("scheduleFollowupDialog");

const scheduleFollowup = ({ recurring = false, selectedDate = undefined } = {}) => {
    const action_data = {
        message_id: messageId,
        lineIdx: lineIdx,
        action_params: {
            calendar_event_id: data.action_params.calendar_event_id,
            invite_description: inviteDescription.value,
            event_at_confirmed: DateTime.fromJSDate(selectedDate ?? date.value).toISO(),
            recurring: recurring,
        },
        action_state: {
            submitted: true,
        },
    };
    emitter.emit("choose_followup", action_data);
    date.value = selectedDate;
    logUserInteraction("followup_scheduled", {}, coachingSessionId);

    // the way the server code works, you cannot send dismissed true and submitted true,
    // dismissed will cause a short circuit in the logic so we do it sequentially after the above
    // vpoc/apps/events/tasks.py:process_action
    dismissAction({ emitter, eventName: "followup_update", line: action_data, messageId, lineIdx });
};
</script>

<style scoped>
.iconBold {
    -webkit-text-stroke: 1px;
}
</style>
