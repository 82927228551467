<template>
    <!-- close and cancel are events that the native html dialog emits, the component does not have the emit defined -->
    <BaseDialog :inline="isInline" class="border-2 border-[#E8E8E8]" @cancel="handleDismiss" @close="handleDismiss">
        <div class="max-w-[700px] antialiased">
            <div class="flex justify-between px-4 items-center border-b-2 border-[#E8E8E8]">
                <p class="text-base font-semibold tracking-tight">Choose Style</p>
                <button type="button" class="bi bi-x text-2xl" @click="handleDismiss"></button>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-3 grid-rows-3 md:grid-rows-2 gap-0.5 bg-[#E8E8E8] rounded-xl">
                <button
                    v-for="({ value, title, description, MarbleComponent }, index) of COACHING_STYLES"
                    :key="title"
                    class="flex text-left gap-2 md:gap-3 items-center px-4 w-full md:max-w-60 min-h-36 relative transition-all"
                    :class="{
                        'rounded-bl-xl': index === 3,
                        'rounded-br-xl': index === 5,
                        'bg-white': value !== selectedCoachingStyle,
                        'bg-[#F7FAFF]': value === selectedCoachingStyle,
                    }"
                    @click="setSelectedCoachingStyle(value)">
                    <i v-if="value === selectedCoachingStyle" class="bi bi-check-lg text-[#555BA2] absolute text-2xl top-2 right-2"></i>
                    <div class="min-w-8 lg:min-w-16">
                        <component :is="MarbleComponent" />
                    </div>
                    <div>
                        <p class="text-base font-semibold mt-4 tracking-tight">{{ title }}</p>
                        <p class="text-[10px] font-semibold text-[#8C8C8C] tracking-tight">{{ description }}</p>
                    </div>
                </button>
            </div>
        </div>
    </BaseDialog>
</template>

<script setup>
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import GeneralCoachMarble from "~vue/components/navigation/CoachingModeMarbles/GeneralCoachMarble.vue";
import GoalSettingCoachMarble from "~vue/components/navigation/CoachingModeMarbles/GoalSettingCoachMarble.vue";
import ReflectiveCoachMarble from "~vue/components/navigation/CoachingModeMarbles/ReflectiveCoachMarble.vue";
import RolePlayCoachMarble from "~vue/components/navigation/CoachingModeMarbles/RolePlayCoachMarble.vue";
import SkillBuildingCoachMarble from "~vue/components/navigation/CoachingModeMarbles/SkillBuildingCoachMarble.vue";
import TaskCoachMarble from "~vue/components/navigation/CoachingModeMarbles/TaskCoachMarble.vue";
import { COACHING_MODES, useChatStore } from "~vue/stores/chatStore";
import { computed, inject } from "vue";

import BaseDialog from "./components/BaseDialog.vue";
import { useCoachingModeSelect } from "./composables/useCoachingModeSelect";

const { emitter } = inject("globalProperties");

const COACHING_STYLES = [
    { value: COACHING_MODES.GENERAL, title: "Default", description: "A balanced approach that adapts to your needs in real-time", MarbleComponent: GeneralCoachMarble },
    {
        value: COACHING_MODES.REFLECTIVE,
        title: "Reflective",
        description: "Encourages introspection and helps you uncover deeper insights",
        MarbleComponent: ReflectiveCoachMarble,
    },
    { value: COACHING_MODES.SKILL_BUILDING, title: "Skill Building", description: "Supports learning and development of new skills", MarbleComponent: SkillBuildingCoachMarble },
    { value: COACHING_MODES.TASK, title: "Execute", description: "Focused on action, outcomes, and getting things done", MarbleComponent: TaskCoachMarble },
    { value: COACHING_MODES.ROLE_PLAY, title: "Role Play", description: "Simulates real-world conversations to practice and prepare", MarbleComponent: RolePlayCoachMarble },
    { value: COACHING_MODES.GOAL_SETTING, title: "Goal Setting", description: "Helps define and achieve meaningful objectives", MarbleComponent: GoalSettingCoachMarble },
];

const { inline } = defineProps({
    // whether to inline the dialog or not, mobile dialog is always false
    inline: { type: Boolean, default: false },
});

const { handleCoachingModeSelect } = useCoachingModeSelect(emitter);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isInline = computed(() => inline && breakpoints.greaterOrEqual("md").value);
const selectedCoachingStyle = computed(() => useChatStore.coachingMode);

const emit = defineEmits(["cancel", "close"]);
const setSelectedCoachingStyle = (coachingStyle) => {
    handleCoachingModeSelect(coachingStyle);
};

const handleDismiss = () => {
    emit("cancel");
    emit("close");
};
</script>

<style scoped></style>
