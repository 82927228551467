<template>
    <MarkdownText class="font-semibold" :text="data.action_params.content" />
    <template v-if="data.action_params.link">
        <a class="font-semibold text-[#555BA2] text-center block w-full py-3 px-6" :href="data.action_params.link" target="_blank" @click="submit">
            {{ data.action_params.link_text }}
        </a>
    </template>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject } from "vue";

import MarkdownText from "./MarkdownText.vue";

const props = defineProps({
    data: { type: Object, required: true },
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
});

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

function submit() {
    emitter.emit("customizable_text", {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        action_state: {
            submitted: true,
            minimized: true,
        },
    });

    logUserInteraction("customizable_text_submitted", {}, coachingSessionId);
}
</script>
