<template>
    <Transition name="fade">
        <div v-if="!isTransitioning && !showWidgetList" class="z-20 top-0 right-0 absolute flex justify-end items-center gap-2 p-10">
            <button
                v-if="showWrapUp"
                class="transition-colors text-base font-semibold leading-normal tracking-[-0.64px] text-[#555BA2] hover:text-[#4B508F] flex items-center gap-2 py-3 px-6 bg-white hover:bg-[#F9F9F9] rounded-3xl border-2 border-[#E8E8E8]"
                @click="emitter.emit('wrap_up_chat')">
                Wrap up
            </button>
            <ChatWidgetListToggle :visible-widget-count="visibleWidgetCount" :is-open="showWidgetList" @click="$emit('toggleShowWidgetList')" />
        </div>
    </Transition>
    <Transition name="fade-close">
        <!-- Note, button is translated -88px horizontally. 40px is the spacing with the sidebar, and 48px is the width of the button itself. -->
        <button
            v-if="!isTransitioning && showWidgetList"
            type="button"
            data-tip="Close Notes"
            class="tooltip tooltip-left z-20 translate-colors absolute top-0 translate-y-10 -translate-x-[88px] flex items-center justify-center w-12 h-12 bg-white hover:bg-[#F9F9F9] rounded-full border-2 border-[#E8E8E8]"
            @click="$emit('toggleShowWidgetList')">
            <X foreground-class="fill-[#8C8C8C]" height="12" width="12" />
        </button>
    </Transition>
    <Transition name="slide" @before-leave="transitionStart" @after-leave="transitionEnd" @before-enter="transitionStart" @after-enter="transitionEnd">
        <div v-show="showWidgetList" class="grow flex-col w-[400px] flex bg-white border-l-2 border-l-[#E8E8E8] max-h-full overflow-y-hidden">
            <ChatWidgetList
                :visible-widget-count="visibleWidgetCount"
                :role-play-adjustment-default="rolePlayAdjustmentDefault"
                :role-play-adjustments="rolePlayAdjustments"
                :messages="messages"
                :is-open="showWidgetList"
                :show-wrap-up="showWrapUp"
                @expand="$emit('toggleShowWidgetList')" />
        </div>
    </Transition>
</template>

<script setup>
import X from "~vue/icons/X.vue";
import { inject } from "vue";

import ChatWidgetList from "./ChatWidgetList.vue";
import ChatWidgetListToggle from "./ChatWidgetListToggle.vue";

defineProps({
    isTransitioning: {
        type: Boolean,
        default: false,
    },
    rolePlayAdjustmentDefault: {
        type: String,
        default: () => "",
    },
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    messages: {
        type: Array,
        default: () => [],
    },
    showWidgetList: {
        type: Boolean,
        default: true,
    },
    showWrapUp: {
        type: Boolean,
        default: false,
    },
    visibleWidgetCount: {
        type: Number,
        default: 0,
    },
});

const { emitter } = inject("globalProperties");

const emit = defineEmits(["toggleShowWidgetList", "transitionStart", "transitionEnd"]);

const transitionStart = () => emit("transitionStart");
const transitionEnd = () => emit("transitionEnd");
</script>

<style scoped>
.slide-leave-active,
.slide-enter-active {
    transition: all 300ms ease-out;
}

.slide-leave-from,
.slide-enter-to {
    transform: translateX(0);
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

/*
 * The controls outside the sidebar fade in and out in sync
 * with the sidebar. However, a delay is added when closing
 * so that the controls appears slightly after the
 * sidebar settles. In the other hand, the controls
 * fade out quickly when opening. Hence why two
 * similar but different fade classes are used.
 */

.fade-close-enter-active,
.fade-close-leave-active,
.fade-enter-active {
    transition: opacity 200ms ease-out;
}

.fade-leave-active {
    transition: opacity 50ms ease-in;
}

.fade-close-enter-active,
.fade-enter-active {
    transition-delay: 100ms;
}

.fade-close-enter-from,
.fade-close-leave-to,
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
