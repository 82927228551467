<template>
    <svg viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path
                id="Vector"
                d="M13.9816 24.8546L8.96909 21.7746L3.9566 24.915C3.53386 25.1565 3.05073 24.8546 3.05073 24.3715C3.05073 21.2311 3.05073 18.0908 3.05073 14.89C1.60134 13.4406 0.755859 11.4477 0.755859 9.21323C0.755859 4.68387 4.43973 1 8.96909 1C13.5588 1 17.2427 4.68387 17.2427 9.21323C17.2427 11.4477 16.3368 13.4406 14.9478 14.89V24.3715C14.9478 24.8546 14.4043 25.1565 13.9816 24.8546ZM4.25856 15.9771V23.2844L8.66713 20.5668C8.8483 20.446 9.08987 20.446 9.33143 20.5668L13.74 23.2844V15.9771C12.4114 16.8829 10.7808 17.4868 8.96909 17.4868C7.21774 17.4868 5.58717 16.8829 4.25856 15.9771ZM8.96909 2.20783C5.10404 2.20783 1.96369 5.34818 1.96369 9.21323C1.96369 13.1387 5.10404 16.279 8.96909 16.279C12.8945 16.279 16.0349 13.1387 16.0349 9.21323C16.0349 5.34818 12.8945 2.20783 8.96909 2.20783Z"
                fill="#262626"
                stroke="#262626"
                stroke-width="0.5" />
            <path
                id="Vector_2"
                d="M9.51354 5.77116L10.4798 7.52251L12.4123 7.82446C12.8955 7.94525 13.0766 8.54916 12.7143 8.91151L11.3253 10.3005L11.6272 12.233C11.6876 12.7162 11.2045 13.0785 10.7818 12.8973L8.97001 11.9915L7.21866 12.8973C6.79592 13.0785 6.31279 12.7162 6.37318 12.233L6.61475 10.3005L5.22575 8.85112C4.92379 8.48877 5.10497 7.94525 5.5881 7.88485L7.52062 7.52251L8.48688 5.77116C8.66806 5.34842 9.33236 5.34842 9.51354 5.77116ZM9.51354 8.36799L8.97001 7.34133L8.48688 8.30759C8.3661 8.48877 8.24532 8.60955 8.06414 8.66994L6.91671 8.85112L7.70179 9.6362C7.82258 9.75699 7.88297 9.93816 7.88297 10.1797L7.70179 11.2668L8.66806 10.844C8.84923 10.7232 9.0304 10.7232 9.27197 10.7836L10.2986 11.2668L10.1174 10.1797C10.1174 9.99855 10.1174 9.81738 10.2986 9.6966L11.0837 8.85112L9.93628 8.66994C9.81549 8.60955 9.63432 8.48877 9.51354 8.36799Z"
                fill="#262626"
                stroke="#262626"
                stroke-width="0.5" />
        </g>
    </svg>
</template>
