/**
 * Composable function to handle the the logic needed when selecting coaching modes
 */

import { useChatStore } from "~vue/stores/chatStore";
import { logError } from "~vue/utils/logUtils";

export const useCoachingModeSelect = (emitter) => {
    const handleCoachingModeSelect = async (newCoachingMode) => {
        const oldCoachingMode = useChatStore.coachingMode;
        useChatStore.setCoachingMode(newCoachingMode);
        const switchMessage = useChatStore.coachingModeOptions.find((item) => item.coaching_mode === newCoachingMode)?.switch_message;
        if (switchMessage) {
            emitter.emit("user_switched_coaching_mode", { oldCoachingMode, newCoachingMode, switchMessage });
        } else {
            logError("No switch message found for coaching mode", { oldCoachingMode, newCoachingMode });
        }
    };

    return { handleCoachingModeSelect };
};
