<template>
    <div class="h-full max-h-full py-3 flex flex-col overflow-y-hidden">
        <div class="px-6 shrink-0 flex items-center justify-between mb-6 md:mt-8 gap-2 flex-wrap">
            <div class="flex gap-2 items-center shrink-0">
                <button type="button" class="shrink-0 md:hidden flex items-center justify-center w-10 h-10 bg-white rounded-full border-2 border-[#E8E8E8]" @click="emit('close')">
                    <X foreground-class="fill-[#8C8C8C]" height="12" width="12" />
                </button>
                <div class="grow text-base tracking-[-0.64px] font-semibold text-[#262626]"><span class="md:inline hidden">Nadia's</span> Notes</div>
            </div>
            <div class="flex items-center gap-1 shrink-0">
                <button
                    :disabled="!showWrapUp"
                    class="transition-colors disabled:text-[#8C8C8C] disabled:bg-[#F5F5F5] text-base font-semibold leading-normal tracking-[-0.64px] flex items-center gap-2 py-[6px] px-4 bg-white hover:bg-[#F9F9F9] rounded-full border-2 border-[#E8E8E8]"
                    @click="emitter.emit('wrap_up_chat')">
                    <i class="bi bi-send"></i>
                    Email
                </button>
                <button
                    :disabled="!showWrapUp"
                    class="transition-colors disabled:text-[#8C8C8C] disabled:bg-[#F5F5F5] text-base font-semibold leading-normal tracking-[-0.64px] text-[#555BA2] hover:text-[#4B508F] flex items-center gap-2 py-[6px] px-6 bg-white hover:bg-[#F9F9F9] rounded-full border-2 border-[#E8E8E8]"
                    @click="emitter.emit('wrap_up_chat')">
                    Wrap up
                </button>
            </div>
        </div>
        <BaseTabs v-if="hasRolePlayMonitor(messages)" v-model="tabsValue" :tab-ids="tabIds" class="grow overflow-y-auto baseTabs" :unmount-on-hide="false">
            <template #trigger-notes>
                <div class="flex gap-4 items-center justify-center py-2">
                    <i class="bi bi-journal-text text-2xl"></i>
                    Notes
                </div>
            </template>
            <template #content-notes>
                <div class="p-6 flex flex-col gap-6 grow overflow-y-auto">
                    <ChatNadiaNotes :register-focusable-action="registerFocusableAction" :messages="messages" />
                </div>
            </template>
            <template #trigger-role-play>
                <div class="flex gap-4 items-center justify-center py-2">
                    <div class="w-5 h-5"><RolePlayCoachMarble /></div>
                    Roleplay
                </div>
            </template>
            <template #content-role-play>
                <div class="p-6">
                    <ChatRolePlayInsights :messages="messages" :role-play-adjustments="rolePlayAdjustments" :role-play-adjustment-default="rolePlayAdjustmentDefault" />
                </div>
            </template>
        </BaseTabs>
        <div v-else class="px-6 flex flex-col gap-6 grow overflow-y-auto">
            <ChatNadiaNotes :register-focusable-action="registerFocusableAction" :messages="messages" />
        </div>
        <div class="px-6 pb-3 shrink-0">
            <HorizontalDivider class="border-2" />
            <CoachingMode />
        </div>
    </div>
</template>

<script setup>
import { ACTION, hasRolePlayMonitor, isRolePlayMessage, lineIsWidget } from "~vue/chatActions.js";
import ChatRolePlayInsights from "~vue/ChatWidgets/ChatRolePlayInsights";
import CoachingMode from "~vue/components/navigation/CoachingMode.vue";
import { CHAT_EVENT } from "~vue/events.js";
import X from "~vue/icons/X.vue";
import { inject, nextTick, onMounted, onUnmounted, ref } from "vue";

import ChatNadiaNotes from "./ChatWidgets/ChatNadiaNotes.vue";
import BaseTabs from "./components/BaseTabs.vue";
import HorizontalDivider from "./components/HorizontalDivider.vue";
import RolePlayCoachMarble from "./components/navigation/CoachingModeMarbles/RolePlayCoachMarble.vue";

const props = defineProps({
    messages: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustmentDefault: {
        type: String,
        default: () => "",
    },
    isOpen: Boolean,
    showWrapUp: {
        type: Boolean,
        default: false,
    },
    visibleWidgetCount: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(["expand", "close"]);

const { emitter } = inject("globalProperties");

const actionRefs = ref(new Map());

onMounted(() => {
    emitter.on(CHAT_EVENT.CHAT_MESSAGE, handleChatMessage);
    emitter.on(CHAT_EVENT.FOCUS_WIDGET, handleFocusWidget);
    emitter.on(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRoleplayTab);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.CHAT_MESSAGE, handleChatMessage);
    emitter.off(CHAT_EVENT.FOCUS_WIDGET, handleFocusWidget);
    emitter.off(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRoleplayTab);
});

const NADIA_NOTES_TABS = {
    NOTES: "notes",
    ROLE_PLAY: "role-play",
};
const tabIds = Object.values(NADIA_NOTES_TABS);
const tabsValue = ref(tabIds[0]);

const focusRoleplayTab = () => {
    tabsValue.value = NADIA_NOTES_TABS.ROLE_PLAY;
};

function registerFocusableAction({ messageId, actionName, lineIdx, onFocus }) {
    const key = refNameForWidget(messageId, actionName, lineIdx);
    actionRefs.value.set(key, onFocus);
}

function refNameForWidget(messageId, actionName, lineIdx) {
    return `${actionName}_${messageId}_${lineIdx}`;
}

function handleFocusWidget({ actionName, messageId, lineIdx }) {
    if (actionName === ACTION.ROLE_PLAY_MONITOR) {
        expandRolePlayMonitor();
        return;
    }

    const widgetKey = refNameForWidget(messageId, actionName, lineIdx);
    if (actionRefs.value.has(widgetKey)) {
        const effect = () => {
            actionRefs.value.get(widgetKey)();
        };

        if (!props.isOpen.value) {
            /*
             * Parent component may open the sidebar in the same
             * listener, so we focus the widget in the next tick so that
             * the sidebar UI is shown first.
             */
            nextTick(effect);
        } else {
            effect();
        }
    }
}

function handleChatMessage(socketMessage) {
    const chatMessage = socketMessage.data;

    if (!chatMessage) {
        return;
    }

    if (Array.isArray(chatMessage.lines) && chatMessage.lines.some((l) => lineIsWidget(l))) {
        emitter.emit(CHAT_EVENT.WIDGET_ADDED, chatMessage);
    }

    if (isRolePlayMessage(chatMessage)) {
        expandRolePlayMonitor();
    }
}

function expandRolePlayMonitor() {
    if (!props.isOpen) {
        emit("expand");
    }
    tabsValue.value = NADIA_NOTES_TABS.ROLE_PLAY;
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.baseTabs :deep(.tabsList) {
    @apply mx-6;
}
</style>
