<template>
    <div class="flex flex-col gap-6 pt-3 px-6 relative rounded-lg container">
        <h3 class="text-xl font-semibold tracking-tighter">{{ firstSentence }}</h3>
        <template v-if="isOpen">
            <MarkdownText class="text-base text-[#8C8C8C] font-semibold tracking-tight" :text="restOfInsight" />
            <div class="text-left text-sm font-medium tracking-tight">
                <button v-if="!showSavedSuccess" type="button" class="text-[#8C8C8C] bi bi-bookmark" @click="saveInsight">&nbsp;Save this as a topic to revisit</button>
                <span v-else>
                    <i class="text-[#555BA2] bi bi-bookmark-fill iconBold"></i>
                    &nbsp;Topic saved!
                </span>
            </div>
        </template>
        <BaseButton theme="secondary" class="self-center tracking-tight" @click="toggleOpen">
            {{ isOpen ? "See less" : "See more" }}
        </BaseButton>
    </div>
</template>

<script setup>
/**
 * Serves double duty for displaying two similar widgets, the values insight widget and the internal thinking widget.
 * Both allow saving the insight to a conversation topic for later.
 */
import BaseButton from "~vue/components/BaseButton.vue";
import MarkdownText from "~vue/MarkdownText.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, ref } from "vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const props = defineProps({
    data: { type: Object, default: () => ({}) },
    role: { type: String, default: "user" },
    messageId: { type: Number, default: 0 },
    lineIdx: { type: Number, default: 0 },
    message: { type: Object, default: () => ({}) },
    action_type: { type: String, default: "" },
});

const isOpen = ref(false);
const showSavedSuccess = ref(false);

const insight = computed(() => props.data.action_params.final_response);

const firstSentence = computed(() => {
    return insight.value.split(". ")[0] + ".";
});

const restOfInsight = computed(() => {
    return insight.value.split(". ").slice(1).join(". ");
});

function toggleOpen() {
    isOpen.value = !isOpen.value;
    logUserInteraction(`${props.action_type}_minimize`, { minimized: props.data.action_state.minimized }, coachingSessionId);
}

function saveInsight() {
    emitter.emit(props.action_type, {
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        insight: insight.value,
        coaching_session_id: coachingSessionId,
    });
    logUserInteraction(`${props.action_type}_save_insight`, {}, coachingSessionId);
    showSavedSuccess.value = true;
}
</script>

<style scoped>
.iconBold {
    -webkit-text-stroke: 1px;
}

.container {
    background: linear-gradient(125deg, rgba(255, 40, 145, 0.04) -22.98%, rgba(0, 63, 220, 0.04) 113.56%);
}

.container::before {
    content: "";
    @apply rounded-lg absolute inset-0 border-2 border-transparent pointer-events-none;
    background: linear-gradient(var(--rotate), #ff238f 0%, #555ba2 100%) border-box;
    mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
}
</style>
