<template>
    <BaseAccordion class="accordion" :unmount-on-hide="false" :accordion-ids="accordionIds">
        <template #expandIcon><i class="bi bi-plus text-xl"></i></template>
        <template #collapseIcon><i class="bi bi-dash text-xl"></i></template>
        <template v-for="id in accordionIds" :key="id" #[makeTriggerSlot(id)]>
            <slot :name="`trigger-${id}`"></slot>
        </template>
        <template v-for="id in accordionIds" :key="id" #[makeContentSlot(id)]>
            <div class="py-3">
                <slot :name="`content-${id}`"></slot>
            </div>
        </template>
    </BaseAccordion>
</template>

<script>
export const makeTriggerSlot = (id) => `trigger-${id}`;
export const makeContentSlot = (id) => `content-${id}`;
</script>

<script setup>
import BaseAccordion from "~vue/components/BaseAccordion.vue";

defineProps({
    accordionIds: { type: Array, required: true },
});
</script>

<style scoped>
/* override base accordion styles */
.accordion :deep(.trigger) {
    @apply rounded-none mb-1;
}
</style>
