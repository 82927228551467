<template>
    <ChatWidgetSection title="Role Play Style">
        <template #content>
            <div class="flex flex-col gap-1">
                <button
                    v-for="option of rolePlayAdjustments"
                    :key="option[0]"
                    type="button"
                    class="w-full flex items-center h-[60px] justify-between p-3 font-semibold text-[#555BA2] hover:opacity-75 tracking-tight rounded-xl border-2 transition-all"
                    :class="[currentRolePlayAdjustValue === option[0] ? 'bg-[#F7FAFF] border-[#B8C9FF]' : 'bg-white border-[#E8E8E8]']"
                    @click="handleAdjustment(option[0])">
                    {{ option[1] }}
                    <i v-if="currentRolePlayAdjustValue === option[0]" class="bi bi-check text-2xl"></i>
                </button>
            </div>
        </template>
    </ChatWidgetSection>
    <HorizontalDivider class="border-2" />
    <ChatWidgetSection title="Feedback">
        <template v-if="reversedRolePlayMessages.length === 0" #description>I will show you suggestions and kudos based on your role play conversation</template>
        <template #content>
            <ChatWidgetAccordion v-model="accordionModel" :accordion-ids="accordionIds" :unmount-on-hide="false">
                <template #[makeTriggerSlot(ROLE_PLAY_ACCORDION_ID.SUGGESTION)]>
                    <ChatWidgetTitle :show-status="suggestionRolePlayMessages.length > 0">
                        <template #icon><LightBulb height="24" width="24" /></template>
                        <template #text>{{ suggestionSectionTitle }}</template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ROLE_PLAY_ACCORDION_ID.SUGGESTION)]>
                    <ChatRolePlayFeedback :focused-feedback="focusedFeedback" :role-play-messages="suggestionRolePlayMessages" :ref-for-line="refForLine" />
                </template>
                <template #[makeTriggerSlot(ROLE_PLAY_ACCORDION_ID.PRAISE)]>
                    <ChatWidgetTitle :show-status="praiseRolePlayMessages.length > 0">
                        <template #icon><AwardBadge height="24" width="24" /></template>
                        <template #text>{{ praiseSectionTitle }}</template>
                    </ChatWidgetTitle>
                </template>
                <template #[makeContentSlot(ROLE_PLAY_ACCORDION_ID.PRAISE)]>
                    <ChatRolePlayFeedback :focused-feedback="focusedFeedback" :role-play-messages="praiseRolePlayMessages" :ref-for-line="refForLine" />
                </template>
            </ChatWidgetAccordion>
        </template>
    </ChatWidgetSection>
</template>

<script setup>
import { ACTION, getRolePlayMonitorParams, isRolePlayMessage, pluckAction } from "~vue/chatActions.js";
import ChatWidgetAccordion, { makeContentSlot, makeTriggerSlot } from "~vue/ChatWidgets/ChatWidgetAccordion.vue";
import ChatWidgetSection from "~vue/ChatWidgets/ChatWidgetSection.vue";
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import { CHAT_EVENT } from "~vue/events.js";
import AwardBadge from "~vue/icons/AwardBadge.vue";
import LightBulb from "~vue/icons/LightBulb.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

import ChatRolePlayFeedback from "./ChatRolePlayFeedback.vue";
import ChatWidgetTitle from "./ChatWidgetTitle.vue";

const ROLE_PLAY_ACCORDION_ID = {
    SUGGESTION: "suggestion",
    PRAISE: "praise",
};

const accordionIds = Object.values(ROLE_PLAY_ACCORDION_ID);

const props = defineProps({
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustmentDefault: {
        type: String,
        default: "IS_OK", // default value for role play adjust, defined in vpoc/apps/chat/constants.py
    },
    messages: {
        type: Array,
        default: () => [],
    },
    showWidgetList: {
        type: Boolean,
        default: true,
    },
});

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");
const actionRefs = ref(new Map());
const focusedFeedback = ref(null);

const reversedRolePlayMessages = computed(() => props.messages.filter((m) => isRolePlayMessage(m)).toReversed());
const suggestionRolePlayMessages = computed(() => reversedRolePlayMessages.value.filter((m) => getRolePlayMonitorParams(m).sentiment === "suggestion"));
const praiseRolePlayMessages = computed(() => reversedRolePlayMessages.value.filter((m) => getRolePlayMonitorParams(m).sentiment === "praise"));
const suggestionSectionTitle = computed(() => {
    const count = suggestionRolePlayMessages.value.length;
    if (count === 0) {
        return "Suggestions";
    }
    return `${count} Suggestion${count === 1 ? "" : "s"}`;
});
const praiseSectionTitle = computed(() => {
    const count = praiseRolePlayMessages.value.length;
    if (count === 0) {
        return "Kudos";
    }
    return `${count} Kudos`;
});

const lastRolePlayAdjustAction = computed(() => {
    return pluckAction(props.messages.toReversed(), ACTION.ROLE_PLAY_ADJUST, true);
});

const currentRolePlayAdjustValue = computed(() => {
    if (lastRolePlayAdjustAction.value) {
        return lastRolePlayAdjustAction.value.line.action_params.adjustment;
    }

    return props.rolePlayAdjustmentDefault;
});

function refForLine(element, messageId) {
    if (element) {
        actionRefs.value.set(messageId, element);
    }
}

// Creates a new role_play_adjust action in the last message. If it already exists, then it simply updates the adjustment.
function handleAdjustment(adjustment) {
    let lineIdx;
    const lastMessageId = props.messages[props.messages.length - 1].chat_message_id;

    if (lastRolePlayAdjustAction.value && lastRolePlayAdjustAction.value.message.chat_message_id === lastMessageId) {
        lineIdx = lastRolePlayAdjustAction.value.lineIdx;
    }

    emitter.emit(ACTION.ROLE_PLAY_ADJUST, {
        message_id: props.messages[props.messages.length - 1].chat_message_id,
        lineIdx,
        action_state: {
            submitted: true,
        },
        action_params: {
            adjustment,
        },
    });
    logUserInteraction("role_play_adjust_submitted", { adjustment }, coachingSessionId);
}

const accordionModel = ref([]);

function focusRolePlayFeedback(messageId) {
    const SCROLL_INTO_VIEW_DELAY = 200;
    const REMOVE_FOCUS_DELAY = 1000;
    const actionRef = actionRefs.value.get(messageId);

    // handles opening the correct accordion section housing the feedback msg
    const feedbackSentiment = getRolePlayMonitorParams(props.messages.find((m) => m.chat_message_id === messageId)).sentiment;
    if (!accordionModel.value.includes(feedbackSentiment)) {
        // adds feedback sentiment into the accordionModel state so that the accordion opens
        accordionModel.value = [...accordionModel.value, feedbackSentiment];
    }
    // short delay added to ensure the accordion open animation completes before scrolling
    setTimeout(() => {
        if (actionRef) {
            actionRef.scrollIntoView({ behavior: "smooth" });
            focusedFeedback.value = messageId;
            setTimeout(() => {
                focusedFeedback.value = null;
            }, REMOVE_FOCUS_DELAY);
        }
    }, SCROLL_INTO_VIEW_DELAY);
}

onMounted(() => {
    emitter.on(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRolePlayFeedback);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRolePlayFeedback);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
