<template>
    <ActionItemsList :action-items="data.action_params.action_items" @check="handleCheck" />
</template>

<script setup>
import ActionItemsList from "~vue/ActionItemsList.vue";
import { inject } from "vue";

import { logUserInteraction } from "./utils/logUtils";

const { data, messageId, lineIdx } = defineProps({
    data: { type: Object, default: () => ({}) },
    messageId: { type: Number, default: 0 },
    lineIdx: { type: Number, default: 0 },
});
const coachingSessionId = inject("coachingSessionId");
const { emitter } = inject("globalProperties");

const handleCheck = (actionItemIndex) => {
    const item = data.action_params.action_items[actionItemIndex];
    item.checked = !item.checked;

    const action_data = {
        subaction: "check_item",
        item_index: actionItemIndex,
        message_id: messageId,
        lineIdx: lineIdx,
        action_params: data.action_params,
        action_state: {
            submitted: false,
        },
    };
    emitter.emit("action_items", action_data);
    logUserInteraction("action_items_checked", {}, coachingSessionId);
};
</script>
