<template>
    <div class="flex flex-col gap-4">
        <div v-for="(item, idx) in inferences" :key="idx" class="tracking-[-0.64px] flex gap-4">
            <div class="grow">
                <p class="font-semibold">{{ item.profile_question_key }}</p>
                <p class="text-[#8C8C8C] font-semibold">{{ item.value }}</p>
            </div>
            <button
                v-if="!hideForgetButton"
                type="button"
                class="shrink-0 bi bi-trash text-[#8C8C8C]"
                :title="`Remove ${item.profile_question_key}`"
                @click="forgetInference(idx)" />
        </div>
    </div>
</template>

<script setup>
import { inject } from "vue";

import { logUserInteraction } from "./utils/logUtils";

const coachingSessionId = inject("coachingSessionId");

const { inferences } = defineProps({
    inferences: { type: Array, default: () => [] },
    hideForgetButton: { type: Boolean, default: false },
});

const emit = defineEmits(["forget-inference"]);

function forgetInference(inferenceIndex) {
    const inference = inferences[inferenceIndex];
    emit("forget-inference", { id: inference.id });
    logUserInteraction("forget_inference", { inference }, coachingSessionId);
}
</script>

<style scoped></style>
